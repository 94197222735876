// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  --ion-font-family: 'Lato';
  /** primary **/
  --ion-color-primary: #034EA2 !important;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #074792 !important;
  --ion-color-primary-tint: #0955af !important;

  /** secondary **/
  --ion-color-secondary: #711E82;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #611970;
  --ion-color-secondary-tint: #7b208e;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #084FA3;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #074792;
  --ion-color-warning-tint: #0955af;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #ADADAD;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #9f9e9e;
  --ion-color-medium-tint: #b7b6b6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Custom vars **/
  --dark_bg_color: #333333;
  --light_bg_color: #ffffff;
  --light_grey_bg_color: #F5F5F5;
  --grey_text_color: #5B5E71;
  --primary-link-color: #6096F6;
  --error-message-color: #f32853;
  --primary-text-color: #333333;
  --secondary-text-color: #373737;
  --aesculap-blue: #034EA2;
  --icons-color: #ffffff;
  --menu-text-color: #231F20;
  --dark-red-color: #C5004B;
  --text-color: #666670;
  --mid-dark-grey: #47464B;
  --text-black: #000000;
  --grey-text: #828282;
  --light-grey-text: #97989B;
  --light-grey-text-hint: #353F4B;

  --swiper-pagination-color: var(--aesculap-blue);
}

ion-toolbar {
  --background: transparent;
  --min-height: 66px;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;

  &.np {
    padding-top: 0 !important;
  }

  &.sub-toolbar {
    --min-height: 36px;

    &.top {
      --min-height: 70px;
      --background: #084FA3;
      max-width: 100%;

      @media (max-width: 767px) {
        --min-height: 54px;
      }

      img {
        margin-top: 7px;
      }
    }

    /*&.collapsed {
      --min-height: 120px;
    }*/
  }
}

ion-footer {
  ion-toolbar {
    --background: #ffffff;
    --min-height: 56px;
  }
}

.ios {
  ion-toolbar {
    &.sub-toolbar {
      /*&.top {
        --min-height: 36px;
      }*/

      &.collapsed {
        --min-height: 92px;

        @media (max-width: 700px) {
          --min-height: 122px;
        }

        @media (max-width: 390px) {
          --min-height: 154px;
        }

        @media (max-width: 375px) {
          --min-height: 184px;
        }
      }
    }
  }
}

ion-item {
  --inner-border-width: 0;
  --border-width: 0;
  --min-height: auto;
  --background-focused: transparent;
  --background: transparent;
  --highlight-background: transparent;
}

ion-menu {
  --background: #ffffff;
  --ion-background-color: #ffffff;
  --width: 320px;

  @media (max-width: 375px) {
    --width: 100%;
  }

  ion-content {
    --ion-background-color: #ffffff;
  }

  ion-item {
    --ion-color-primary: #d8d8d8;
    --min-height: 24px;
  }

}

ion-list {
  --ion-item-background: transparent;
}

ion-button {
  letter-spacing: 0.3px;
  --border-radius: 41px;
  height: 45px;
  min-height: 45px;
  text-transform: none;
  font-size: 16px;
  box-shadow: 0 0 0;
}

ion-checkbox {
  --border-color: #787878;
  --background: #ffffff;
}

ion-menu-button {
  --color: #5E98F0;
}

ion-content {
  --ion-background-color: var(--light_grey_bg_color);
}

ion-loading {
  --max-width: 316px !important;
}

ion-modal {
  --background: #f2f3f8 !important;
  --border-radius: 0 !important;
  --max-width: 360px !important;
  --height: 80% !important;
  color: #414450;

  &.modal-closeReqList {
    --height: 264px !important;
    --max-width: 320px !important;
    --width: 94% !important;
    --background: #ffffff !important;
    --border-radius: 8px !important;
  }

  &.modal-invite {
    --height: 200px !important;
  }

  &.modal-scanInstruction {
    --height: 500px !important;
  }

  &.modal-imagePreview {
    --height: auto !important;
  }

  &.modal-alert {
    --height: 180px !important;
    --border-radius: 32px !important;
  }

  &.modal-inviteFriend {
    --height: 380px !important;
    --border-radius: 32px !important;
  }

  &.modal-beta {
    --height: auto !important;
    --max-width: 320px !important;
  }

  &.modal-guideInfo {
    --height: auto !important;
    --background: #ffffff !important;
    --max-width: 300px !important;
    align-items: start;
    margin-top: 90px;
  }

  &.modal-welcome {
    --height: 575px !important;
    z-index: 999999 !important;

    @media (max-width: 767px) {
      --height: 100% !important;
      --max-width: 100% !important;
    }
  }

  &.modal-previewPDF {
    --border-radius: 32px !important;
    --max-width: 800px !important;
    --width: 96%;
    --height: 90% !important;
  }

  &.modal-catalogSlider {
    --border-radius: 32px !important;
    --max-width: 90vh !important;
    --width: 96%;
    --height: auto !important;

    @media (max-width: 767px) {
      --height: 100% !important;
      --max-width: 100% !important;
      --width: 100%;
      --border-radius: 0 !important;
    }
  }

  &.modal-qr {
    --background: #ffffff !important;
    --height: 575px !important;
    z-index: 999999 !important;

    @media (max-width: 767px) {
      --height: 100% !important;
      --max-width: 100% !important;
    }
  }
}
