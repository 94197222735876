app-auto-complete {
  overflow: hidden !important;
  width:    90vw;
  display:  inline-block;

  ion-searchbar {
    padding: 1px !important;
  }

  .disabled input.searchbar-input {
    pointer-events: none;
    cursor: default;
  }

  ul {
    position:        absolute;
    width:           90vw;
    margin-top:      0;
    background:      #FFF;
    list-style-type: none;
    padding:         0;
    left:            16px;
    z-index:         999;
    box-shadow:      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    li {
      padding:       15px;
      border-bottom: 1px solid #c1c1c1;

      span {
        pointer-events: none;
      }
    }

    ion-auto-complete-item {
      height: 40px;
      width:  100%;
    }

    li:last-child {
      border: none;
    }

    li:focus,
    li.focus {
      cursor:     pointer;
      background: #f1f1f1
    }
  }

  .hidden {
    display: none;
  }

  .loading input.searchbar-input {
    background:      white url(/assets/loading.gif) no-repeat right 4px center;
    background-size: 25px 25px;
  }

  .searchbar-clear-button.sc-ion-searchbar-md {
    right: 34px;
  }

  .selected-items {
    float: left;
  }
}
