// Loop to generate padding classes. For the users of this utility, example:  pl-1 is equal to padding-left: 0.25rem; /* 4px */
@for $i from 0 through 10 {
    $value: $i * 0.25rem;
    
    .p-#{$i} {
      padding: $value;
    }
    
    .px-#{$i} {
      padding-left: $value;
      padding-right: $value;
    }
    
    .py-#{$i} {
      padding-top: $value;
      padding-bottom: $value;
    }
    
    .ps-#{$i} {
      padding-inline-start: $value;
    }
    
    .pe-#{$i} {
      padding-inline-end: $value;
    }
    
    .pt-#{$i} {
      padding-top: $value;
    }
    
    .pr-#{$i} {
      padding-right: $value;
    }
    
    .pb-#{$i} {
      padding-bottom: $value;
    }
    
    .pl-#{$i} {
      padding-left: $value;
    }
  }
// Loop to generate margin classes. For the users of this utility, example:  ml-1 is equal to margin-left: 0.25rem; /* 4px */
@for $i from 0 through 10 {
    $value: $i * 0.25rem;
    
    .m-#{$i} {
      margin: $value;
    }
    
    .mx-#{$i} {
      margin-left: $value;
      margin-right: $value;
    }
    
    .my-#{$i} {
      margin-top: $value;
      margin-bottom: $value;
    }
    
    .ms-#{$i} {
      margin-inline-start: $value;
    }
    
    .me-#{$i} {
      margin-inline-end: $value;
    }
    
    .mt-#{$i} {
      margin-top: $value;
    }
    
    .mr-#{$i} {
      margin-right: $value;
    }
    
    .mb-#{$i} {
      margin-bottom: $value;
    }
    
    .ml-#{$i} {
      margin-left: $value;
    }
  }
  